import React, { useEffect, useState } from 'react';
import io from 'socket.io-client'

import Player from './components/Player'
import config from './config'
import './App.css';

function App() {

  const [socket, setSocket] = useState();
  const [players, setPlayers] = useState([]);
  const [socketStatus, setSocketStatus] = useState('disconnected');

  useEffect(() => {
    const socket = io(config.urls.socket, {
      query: {
        token: 'doug',
      }
    })

    socket.on('connect', () => {
      setSocketStatus('connected')
    });

    socket.on('disconnect', () => {
      setSocketStatus('disconnected')
    });

    socket.on('reconnect', () => {
      setSocketStatus('connected')
    });

    socket.on('players', data => {
      setPlayers(data)
    })

    setSocket(socket)

  }, [])

  const emit = (message, id, callback) => socket.emit(message, id, callback)

  const renderPlayers = () => {
    return players.map(data => <Player key={data.playerId} data={data} emit={emit} />)
  }

  return (
    <div className="container">
      <h1>Admin</h1>
      <p><strong>{socketStatus}</strong> {config.urls.socket}</p>
      {renderPlayers()}
    </div>
  );
}

export default App;
